<template>
  <div class="content_body stockConfig" 
  >
    <el-form ref="form" :model="stockFormDate" label-width="150px">
       <div class="tip marbm_10 font_14 color_333" style="margin-top:0">库存设置</div>
       <el-form-item label="是否允许负库存出库 :">
        <el-radio-group v-model="stockFormDate.IsAllowNegativeOutbound">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-button size="small" type="primary" :loading="saveLoadingBool" @click="onSave">保存</el-button>
  </div>
</template>

<script>
import API from "@/api/PSI/Product/stockConfig";
export default {
    name:"stockConfig",
    data(){
        return{
            stockFormDate:{
                IsAllowNegativeOutbound:''
            },
            saveLoadingBool:false
        }
    },
    methods:{
        // 库存设置详情
        StockConfigAll(){
            const that = this
            API.StockConfigAll().then(res=>{
                if(res.StateCode==200){
                    that.stockFormDate.IsAllowNegativeOutbound=res.Data.IsAllowNegativeOutbound
                }else{
                    that.$message.error({
                        message:res.Message,
                        duration: 2000
                    });
                }
                
            })
        },
        // 库存设置
        StockConfigCreate(){
            const that = this
            that.saveLoadingBool=true
            const params ={
                IsAllowNegativeOutbound:that.stockFormDate.IsAllowNegativeOutbound
            }
            API.StockConfigCreate(params).then(res=>{
                if(res.StateCode==200){
                    that.$message.success({
                        message:"保存成功",
                        duration: 2000
                    });
                }else{
                    that.$message.error({
                        message:res.Message,
                        duration: 2000
                    });
                }
            })
            .finally(()=>{
                that.saveLoadingBool=false
            })
        },
        // 保存
        onSave(){
            this.StockConfigCreate()
        },
    },
    mounted(){
        // 库存设置详情
        this.StockConfigAll()
    }

}
</script>

<style  lang="scss">
 .stockConfig {}

</style>>